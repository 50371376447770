'use client'

import { CheckIcon } from '@heroicons/react/24/outline'
import { Button } from '@tebuto/buttons/Button'
import FormField from '@tebuto/forms/Field'
import OtpInput from '@tebuto/forms/OTPInput'
import { EMAIL_REGEX, classNames } from '@tebuto/functions'
import ConfirmationDialogue from '@tebuto/modals/ConfirmationDialogue'
import ExtraSmallText from '@tebuto/typography/ExtraSmallText'
import Heading from '@tebuto/typography/Heading'
import SmallText from '@tebuto/typography/SmallText'
import useTimer from '@tebuto/utility/useTimer'
import { Form, Formik, useFormikContext } from 'formik'
import Link from 'next/link'
import { useSearchParams } from 'next/navigation'
import { Dispatch, createContext, useContext, useEffect, useState } from 'react'
import { TriggerWithArgs } from 'swr/mutation'
import { object, string } from 'yup'
import { useRequestCode, useSendCode } from './hooks'
import PAGE_TEXTS from './translations'

type LoginStep = 'requestCode' | 'sendCode'
export const LoginStepContext = createContext<{ step: LoginStep; setStep: Dispatch<LoginStep>; setEmail: Dispatch<string | null> } & ReturnType<typeof useTimer>>(undefined as any)

export function LoginForm() {
    const [step, setStep] = useState<LoginStep>((localStorage.getItem('loginStep') as LoginStep) || 'requestCode')
    const [email, setEmail] = useState<string | null>(localStorage.getItem('email'))
    const { timer, clearTimer, setTimer } = useTimer()

    const params = useSearchParams()

    useEffect(() => {
        const email = params.get('email')
        if (email) {
            setEmail(email)
            setStep('sendCode')
        }
    }, [])

    return (
        <div className="max-w-[450px] flex flex-col gap-10 bg-white border [@media(min-width:451px)]:rounded-lg my-auto flex-shrink-0 [@media(min-width:451px)]:shadow-xl">
            {/* Header */}
            <div className="py-6 px-10 border-b border-primary-100 bg-gray-50 rounded-t-lg">
                <div className="flex justify-between mb-3">
                    <div>
                        <ExtraSmallText>{PAGE_TEXTS.loginForm.step1}</ExtraSmallText>
                        <SmallText className={classNames('font-medium', step === 'requestCode' ? 'text-gray-600' : 'text-gray-400')}>
                            {PAGE_TEXTS.loginForm.step1Description}
                        </SmallText>
                    </div>
                    <div>
                        <ExtraSmallText>{PAGE_TEXTS.loginForm.step2}</ExtraSmallText>
                        <SmallText className={classNames('font-medium', step === 'sendCode' ? 'text-gray-600' : 'text-gray-400')}>
                            {PAGE_TEXTS.loginForm.step2Description}
                        </SmallText>
                    </div>
                </div>
                <div className="relative w-full h-6 flex items-center">
                    {/* Background bar */}
                    <div className="absolute left-1/2 transform -translate-x-1/2 w-[calc(100%-5rem)] h-2 bg-gray-300" />

                    {/* Dots */}
                    {step === 'requestCode' && <div className={classNames('absolute bg-primary-500 w-4 h-4 rounded-full top-1/2 transform -translate-y-1/2 left-8 z-10')} />}
                    {step === 'sendCode' && (
                        <>
                            <div className={classNames('absolute bg-primary-500 w-6 h-6 rounded-full top-1/2 transform -translate-y-1/2 left-7 z-10')}>
                                <CheckIcon className="p-1 text-white" />
                            </div>
                            <div className={classNames('absolute bg-primary-500 w-4 h-4 rounded-full top-1/2 transform -translate-y-1/2 right-8 z-10')} />
                        </>
                    )}

                    {/* Empty dot outlines */}
                    <div className={classNames('absolute border-primary-500 w-6 h-6 border-2 rounded-full top-1/2 transform -translate-y-1/2 left-7 bg-gray-50 z-0')} />
                    <div
                        className={classNames(
                            'absolute w-6 h-6 border-2 rounded-full top-1/2 transform -translate-y-1/2 right-7 bg-gray-50 z-0',
                            step === 'sendCode' ? 'border-primary-500' : 'border-gray-300'
                        )}
                    />
                </div>
            </div>
            <div className="px-10">
                <LoginStepContext.Provider
                    value={{
                        step,
                        setStep: value => {
                            localStorage.setItem('loginStep', value)
                            setStep(value)
                        },
                        setEmail: value => {
                            if (value) {
                                localStorage.setItem('email', value)
                                setEmail(value)
                            } else {
                                localStorage.removeItem('email')
                            }
                        },
                        timer,
                        clearTimer,
                        setTimer
                    }}
                >
                    {step === 'sendCode' && email ? <SendCodeForm email={email} /> : <RequestCodeForm />}
                </LoginStepContext.Provider>
            </div>
        </div>
    )
}

function RequestCodeForm() {
    const { setTimer: startTimer } = useContext(LoginStepContext)
    const { requestCode, isMutating } = useRequestCode(startTimer)
    return (
        <Formik<RequestCodeFormValues>
            initialValues={{ email: '' }}
            validateOnChange={true}
            validationSchema={requestCodeFormValidationSchema}
            onSubmit={values => requestCode(values)}
        >
            {({ isValid }) => (
                <Form className="flex flex-col">
                    {/* The browser actually doesn't support UTF-8 for emails, although it definitely should (according to the RFC). So we have to disable the validation, but since we're doing it ourselves and send a confirmation otp, it's not a big deal. */}
                    {/* Header */}
                    <div className="mb-6 h-[100px]">
                        <Heading className="mb-3 font-semibold" level={3}>
                            {PAGE_TEXTS.loginForm.requestCode.login}
                        </Heading>
                        <SmallText>{PAGE_TEXTS.loginForm.requestCode.pleaseEnterEmail}</SmallText>
                    </div>
                    {/* Body */}
                    <div className="flex flex-col gap-4 h-[200px]">
                        <FormField name="email" label="Ihre E-Mail Adresse" type="text" placeholder="email@example.com" autoComplete="email" mandatory />
                        <Button type="submit" buttonStyle="large" className="w-full" isLoading={isMutating} disabled={!isValid}>
                            {PAGE_TEXTS.loginForm.requestCode.confirm}
                        </Button>
                    </div>
                    {/* Footer */}
                    <div className="flex pt-4 h-[100px] justify-center">
                        {/* This page doesn't exist yet. See #228 */}
                        <Link href={`${process.env.NEXT_PUBLIC_LANDING_BASE_URL}/funktionsweise`} className="text-primary-600 text-xs underline">
                            {PAGE_TEXTS.loginForm.requestCode.noAccountYet}
                        </Link>
                    </div>
                </Form>
            )}
        </Formik>
    )
}

function SendCodeForm({ email }: { email: string }) {
    const [isLoading, setIsLoading] = useState(false)
    const [error, setError] = useState<string>('')

    const sendCode = useSendCode((error: string) => {
        setError(error)
        setIsLoading(false)
    })

    return (
        <Formik<SendCodeFormValues>
            initialValues={{ otp: '' }}
            validateOnChange={true}
            validationSchema={sendCodeFormValidationSchema}
            onSubmit={values => {
                sendCode(values)
                setIsLoading(true)
            }}
        >
            <SendCodeFormContent email={email} isLoading={isLoading} error={error} sendCode={sendCode} />
        </Formik>
    )
}

function SendCodeFormContent({
    email,
    isLoading,
    error,
    sendCode
}: { email: string; isLoading: boolean; error: string; sendCode: TriggerWithArgs<void, any, 'sendCode', SendCodeFormValues> }) {
    const { setStep, setEmail, timer, setTimer, clearTimer } = useContext(LoginStepContext)
    const { setFieldValue, isValid, dirty } = useFormikContext<SendCodeFormValues>()
    const [resendCodeModalOpen, setResendCodeModalOpen] = useState(false)
    const { requestCode, isMutating } = useRequestCode(setTimer)
    const params = useSearchParams()

    useEffect(() => {
        const otp = params.get('otp')
        if (otp) {
            setFieldValue('otp', otp)
            sendCode({ otp })
        }
    }, [])

    function backToEmailStep() {
        setStep('requestCode')
        setEmail(null)
        clearTimer()
    }

    return (
        <Form className="flex flex-col">
            {/* Header */}
            <div className="mb-6 h-[100px]">
                <Heading className="mb-3 font-semibold" level={3}>
                    {PAGE_TEXTS.loginForm.sendCode.enterCode}
                </Heading>
                <SmallText>{PAGE_TEXTS.loginForm.sendCode.enterCodeDescription}</SmallText>
            </div>
            {/* Body */}
            <div className="flex flex-col gap-4 h-[200px]">
                <OtpInput name="otp" valueLength={6} isLoading={isLoading} />
                <Button type="submit" buttonStyle="large" className="w-full" disabled={!isValid || !dirty} isLoading={isLoading}>
                    {PAGE_TEXTS.loginForm.sendCode.confirm}
                </Button>
                {error && (
                    <div className="flex justify-center">
                        <SmallText className="text-red-500">{error}</SmallText>
                    </div>
                )}
            </div>
            {/* Footer */}
            <div className="flex flex-col gap-3 pt-4 h-[100px] mx-auto">
                <button type="button" onClick={backToEmailStep} className="text-primary-600 text-xs underline">
                    {PAGE_TEXTS.loginForm.sendCode.useDifferentEmail}
                </button>
                <button type="button" onClick={() => setResendCodeModalOpen(true)} className="text-primary-600 text-xs underline">
                    {PAGE_TEXTS.loginForm.sendCode.noCodeReceived}
                </button>
            </div>
            <ConfirmationDialogue
                title={PAGE_TEXTS.loginForm.sendCode.requestNewCodeDialog.title}
                open={resendCodeModalOpen}
                setOpen={setResendCodeModalOpen}
                submitButtonText={PAGE_TEXTS.loginForm.sendCode.requestNewCodeDialog.submit}
                submitButtonEnabled={!timer}
                onSubmit={() => requestCode({ email })}
                isLoading={isMutating}
            >
                {timer ? (
                    <SmallText>{PAGE_TEXTS.loginForm.sendCode.newCodeCooldown(Math.ceil(timer.as('seconds')))}</SmallText>
                ) : (
                    <SmallText>
                        {PAGE_TEXTS.loginForm.sendCode.requestNewCodeDialog.description1}
                        <span className="text-sm font-semibold">{email}</span>
                        {PAGE_TEXTS.loginForm.sendCode.requestNewCodeDialog.description2}
                    </SmallText>
                )}
            </ConfirmationDialogue>
        </Form>
    )
}

export interface RequestCodeFormValues {
    email: string
}

export interface SendCodeFormValues {
    otp: string
}

const sendCodeFormValidationSchema = object().shape({
    otp: string().length(6).matches(/^\d+$/, PAGE_TEXTS.loginForm.sendCode.errors.onlyDigits).required(PAGE_TEXTS.loginForm.sendCode.errors.codeMandatory)
})

const requestCodeFormValidationSchema = object().shape({
    email: string().matches(EMAIL_REGEX, PAGE_TEXTS.loginForm.sendCode.errors.invalidEmail).required(PAGE_TEXTS.loginForm.sendCode.errors.emailMandatory)
})
