import { DateTime, Duration } from 'luxon'
import { useEffect, useState } from 'react'

export default function useTimer() {
    const [seconds, setSeconds] = useState<number | null>()
    const [duration, setDuration] = useState<Duration<true> | null>()

    useEffect(() => {
        if (!seconds) {
            return
        }

        const endTime = DateTime.now().plus({ seconds })
        const interval = setInterval(() => {
            setDuration(() => {
                const remaining = endTime.diffNow()
                if (remaining.as('seconds') <= 0) {
                    clearInterval(interval)
                    return null
                }
                return remaining
            })
        }, 1000)

        return () => clearInterval(interval)
    }, [seconds])

    function setTimer(seconds: number) {
        setSeconds(seconds)
    }

    function clearTimer() {
        setDuration(null)
        setSeconds(null)
    }

    return {
        timer: duration,
        setTimer,
        clearTimer
    }
}
