import { FieldConfig, useField, useFormikContext } from 'formik'
import React, { useEffect, useRef } from 'react'

interface OtpInputProps extends FieldConfig<string> {
    valueLength: number
    isLoading: boolean
}

const OtpInput: React.FC<OtpInputProps> = ({ valueLength, isLoading, ...props }) => {
    const { validateForm, submitForm } = useFormikContext()
    const [field, , helpers] = useField(props.name)
    const { value } = field
    const { setValue, setTouched } = helpers

    const inputsRef = useRef<Record<number, HTMLInputElement | null>>({})

    function setFormValue(value: string) {
        setValue(value, true)
        setTouched(true)
        setTimeout(validateForm, 0)
    }

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>, idx: number) => {
        const val = e.target.value
        if (/\d/.test(val) || val === '') {
            const newOtp = value.split('')
            newOtp[idx] = val
            setFormValue(newOtp.join(''))
            if (idx < valueLength - 1) {
                inputsRef.current[val ? idx + 1 : idx]?.focus()
            }
        }
    }

    const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>, idx: number) => {
        if (isLoading) {
            return
        }
        if (e.key === 'Backspace') {
            if (value[idx]) {
                handleChange({ target: { value: '' } } as React.ChangeEvent<HTMLInputElement>, idx)
            }
            for (let i = valueLength - 1; i >= 0; i--) {
                if (value[i]) {
                    inputsRef.current[i]?.focus()
                    break
                }
            }
        }
        if (e.key === 'Enter' && value.length === valueLength) {
            submitForm()
        }
        if (e.key === 'ArrowRight' && idx < valueLength - 1 && value[idx]) {
            inputsRef.current[idx + 1]?.focus()
        }
        if (e.key === 'ArrowLeft' && idx > 0 && value[idx - 1]) {
            inputsRef.current[idx - 1]?.focus()
        }
    }

    const handlePaste = (e: React.ClipboardEvent<HTMLInputElement>) => {
        if (isLoading) {
            return
        }
        const pasteData = e.clipboardData.getData('text').slice(0, valueLength)
        if (/^\d+$/.test(pasteData)) {
            setFormValue(pasteData)
            inputsRef.current[Math.min(pasteData.length, 6) - 1]?.select()
            setTimeout(validateForm, 0)
            setTimeout(submitForm, 0)
        }
    }

    useEffect(() => {
        inputsRef.current[0]?.focus()
    }, [])

    return (
        <div className="flex w-full justify-between h-[65px] items-center">
            {Array(valueLength)
                .fill(0)
                .map((_, idx) => (
                    <input
                        key={idx}
                        type="text"
                        id="otp"
                        name="otp"
                        value={value[idx] || ''}
                        onChange={e => handleChange(e, idx)}
                        onKeyDown={e => handleKeyDown(e, idx)}
                        onPaste={handlePaste}
                        readOnly={isLoading}
                        //@ts-ignore
                        // biome-ignore lint/suspicious/noAssignInExpressions: If its stupid but it works, it ain't stupid
                        ref={el => (inputsRef.current[idx] = el)}
                        maxLength={1}
                        autoComplete="one-time-code"
                        inputmode="numeric"
                        pattern="[0-9]{6}"
                        required
                        className="w-12 h-12 text-center border border-gray-300 rounded !text-xl focus:ring-2 ring-primary-500 focus:outline-none focus:border-primary-500"
                    />
                ))}
        </div>
    )
}

export default OtpInput
